<template>
  <div>{{ user }}</div>
</template>

<script>
  export default {
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.smAndDown
      },
      size () {
        return this.isMobile ? 33 : 40
      },
      data () {
        return this.record.data
      }
    },
    asyncComputed: {
      async user () {
        const doc = this.record.user && await this.record.user
        if (doc) {
          const data = doc.data()
          if (data) {
            return data
          }
        }
        return ''
      }
    }
  }
</script>

<style lang="sass">
  .avatar-guest
    width: 40px
    height: 40px

    .a
      fill: #004f95
</style>
